import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authenticated } from 'store';

export default function Authenticated(props) {
  const navigate = useNavigate();
  const auth = useRecoilValue(authenticated);
  useEffect(() => {
    if (!auth.check) {
      navigate('/login');
    }
  });
  return props.render;
}
