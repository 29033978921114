import THEME from './theme';
import { WEB, API, DASHBOARD } from './routes';

const BENEFIT = [
  {
    urutan: 1,
    title: 'Lengkap & kredibel',
    content:
      'Menyajikan kasus dari 9 bidang keperawatan  (anak, maternitas, intensif, komunitas, geriatri, gawat darurat, KMB, jiwa, dan keperawatan dasar)  yang sudah direview oleh pakar di bidangnya.',
    image: 'http://placeimg.com/640/480/business',
    background: '#FFFFFF',
    font_color: '#5D5F61',
    id: '2'
  },
  {
    urutan: 2,
    title: 'Melatih Berpikir Kritis',
    content:
      'Aplikasi PASIVIK didesain untuk mendorong mahasiswa dalam menerapkan pola berpikir kritis ketika  menyusun asuhan keperawatan mulai dari pengkajian, menyusun luaran, hingga intervensi keperawatan.',
    image: 'http://placeimg.com/640/480/business',
    background: '#0A5F8E',
    font_color: '#FFFFFF',
    id: '3'
  },
  {
    urutan: 3,
    title: 'Informatif',
    content:
      'PASIVIK Memberikan feedback dari jawaban yang diberikan mahasiswa di setiap tahapannya, sehingga mahasiswa mengetahui jawaban yang tepat pada setiap tahapan proses keperawatan. Smart PASIVIK juga memberikan feedback rekomendasi materi belajar sesuai kebutuhan mahasiswa.',
    image: 'http://placeimg.com/640/480/business',
    background: '#EBF0F4',
    font_color: '#5D5F61',
    id: '4'
  },
  {
    urutan: 4,
    title: 'Berbasis Kecerdasan Buatan',
    content:
      'Menggunakan artificial intelligence  untuk memberikan pengalaman seperti melakukan pengkajian pada pasien sungguhan.',
    image: 'http://placeimg.com/640/480/business',
    background: '#FFFFFF',
    font_color: '#5D5F61',
    id: '1'
  }
];

export { API, BENEFIT, THEME, WEB, DASHBOARD };
