const { atom } = require('recoil');

const authenticated = atom({
  key: 'authenticated',
  default: {
    check: localStorage.getItem('token') ? true : false,
    user: {
      name: localStorage.getItem('name') ? localStorage.getItem('name') : '',
      username: localStorage.getItem('username') ? localStorage.getItem('username') : '',
      email: localStorage.getItem('email') ? localStorage.getItem('email') : '',
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      gender: localStorage.getItem('gender') ? localStorage.getItem('gender') : ''
    }
  }
});

export { authenticated };
