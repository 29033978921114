import { extendTheme } from '@chakra-ui/react';

export const PasivikThemeProvider = extendTheme({
  colors: {
    utama: {
      100: '#0A5F8E'
    },
    text: {
      100: '#5D5F61',
      600: '#3D3F40'
    },
    bg: {
      utama: '#0A5F8E',
      second: '#EBF0F4',
      gradient: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 128.95%)'
    },
    white: '#fff'
  },
  styles: {
    global: {
      // styles for the `body`
      /* width */
      '*::-webkit-scrollbar': {
        width: '5px',
        height: '7px'
      },

      /* Track */
      '*::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 5px grey',
        borderRadius: '10px'
      },

      /* Handle */
      '*::-webkit-scrollbar-thumb': {
        background: '#989B9E',
        borderRadius: '10px'
      },
      body: {
        fontFamily: 'Source Sans Pro',
        bg: '#FFFFFF',
        color: '#5D5F61'
      },
      '.main_content': {
        paddingTop: '180px'
      },
      '.container': {
        width: '100%',
        maxWidth: '1320px !important'
      },
      '.container-inner': {
        width: '100%',
        maxWidth: '1117px !important'
      },
      '.container-fluid': {
        width: '100%',
        maxWidth: '1320px !important'
      },
      '.form-control': {
        width: '100%',
        borderRadius: '10px',
        border: '1px solid #cbd5e0',
        height: '25px'
      },
      '.chakra-table__container': {
        whiteSpace: 'initial !important'
      },
      '.btn_prev': {
        maxWidth: '184px',
        mr: '15px',
        bg: '#FFFFFF',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.12)',
        borderRadius: '8px',
        color: '#0A5F8E',
        padding: '10px 64px'
      },
      '.btn_next': {
        maxWidth: '184px',
        background: '#0A5F8E !important',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.12)',
        borderRadius: '8px',
        color: '#fff',
        padding: '10px 64px'
      },
      '.table': {
        width: '100%',
        border: '1px solid #D8DCE0',
        tr: {
          verticalAlign: 'baseline'
        },
        thead: {
          background: '#EBF0F4',
          color: '#131414',
          fontSize: '14px',
          textTransform: 'capitalize',
          fontWeight: 600,
          tr: {
            th: {
              padding: '6px 16px',
              textAlign: 'left'
            }
          }
        },
        tbody: {
          color: '#3D3F40',
          fontSize: '14px',
          tr: {
            verticalAlign: 'baseline',
            td: {
              padding: '4px 16px',
              borderBottom: '1px solid #D8DCE0',
              borderCollapse: 'collapse'
            },
            '&:hover': {
              background: '#00000013',
              color: '#212529'
            }
          }
        }
      },
      '.table_rekam_medis': {
        tr: {
          td: {
            verticalAlign: 'baseline',
            fontWeight: ' 400',
            fontSize: '14px',
            lineHeight: ' 16px',
            color: '#131414',
            padding: '5px 15px'
          }
        }
      },
      '.invalid-feedback': {
        color: '#f00',
        fontSize: '14px',
        marginBottom: '10px'
      },
      '.card': {
        border: '1px solid #D8DCE0',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.12)',
        borderRadius: '6px',
        overflow: 'hidden',
        padding: '0',
        margin: '0px 0px 16px ',
        '.card-heading': {
          fontWeight: 600,
          fontSize: '16px',
          color: '#131414',
          padding: '6px 16px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          background: '#EBF0F4',
          borderRadius: '0px 6px 0px 0px'
        },
        '.card-body': {
          padding: '16px'
        }
      },
      '.nav_tahap': {
        position: 'relative',
        '::before': {
          content: '""',
          position: 'absolute',
          left: '-28px',
          top: '18%',
          background: '#C7C7C7',
          width: '3px',
          height: '67%'
        },
        '.tahap_rekam_medis': {
          '::before': {
            content: '""',
            position: 'absolute',
            left: '-31px',
            top: '18%',
            background: '#26BF69',
            borderRadius: '50%',
            width: '8px',
            height: '8px'
          },
          '&.selesai': {
            '::after': {
              content: '""',
              position: 'absolute',
              left: '-28px',
              top: '18%',
              background: '#26BF69',
              width: '3px',
              height: '33%'
            }
          }
        },
        '.tahap_tanya_pasien': {
          '::before': {
            content: '""',
            position: 'absolute',
            left: '-31px',
            top: '51%',
            background: '#333846',
            borderRadius: '50%',
            width: '8px',
            height: '8px'
          },
          '&.active': {
            '::before': {
              content: '""',
              position: 'absolute',
              left: '-31px',
              top: '51%',
              background: '#26BF69',
              borderRadius: '50%',
              width: '8px',
              height: '8px'
            }
          },
          '&.selesai': {
            '::before': {
              content: '""',
              position: 'absolute',
              left: '-31px',
              top: '51%',
              background: '#26BF69',
              borderRadius: '50%',
              width: '8px',
              height: '8px'
            },
            '::after': {
              content: '""',
              position: 'absolute',
              left: '-28px',
              top: '52%',
              background: '#26BF69',
              width: '3px',
              height: '33%'
            }
          }
        },
        '.tahap_cek_pasien': {
          '::before': {
            content: '""',
            position: 'absolute',
            left: '-31px',
            top: '84%',
            background: '#333846',
            borderRadius: '50%',
            width: '8px',
            height: '8px'
          },
          '&.active': {
            '::before': {
              content: '""',
              position: 'absolute',
              left: '-31px',
              top: '84%',
              background: '#26BF69',
              borderRadius: '50%',
              width: '8px',
              height: '8px'
            }
          }
        }
      },
      '.tahap': {
        bg: '#fff',
        w: '100%',
        padding: '14px 16px',
        border: '1px solid #EAE9EE',
        borderRadius: '8px',
        '.avatar': {
          bg: '#0A5F8E',
          '.icon': {
            color: '#fff'
          }
        },
        '.subtitle': {
          color: '#B4B6B8',
          fontSize: '14px',
          fontWeight: 400,
          mt: '15px'
        },
        '.title': {
          color: '#3D4859',
          fontSize: '14px',
          fontWeight: 600,
          mb: '8px',
          mt: '8px'
        },
        '.badge': {
          width: '100%',
          bg: '#D9DFF7',
          color: '#0A5F8E',
          fontSize: '14px',
          display: 'inline',
          padding: '2px 6px',
          borderRadius: '6px'
        },
        '&.active': {
          bg: '#0A5F8E',
          '.avatar': {
            bg: '#fff',
            '.icon': {
              color: '#0A5F8E'
            }
          },
          '.subtitle': {
            color: '#fff'
          },
          '.title': {
            color: '#fff'
          },
          '.badge': {
            bg: '#1F81B7',
            color: '#fff'
          }
        },
        '&.selesai': {
          bg: '#F0FCF6',
          border: '1px solid rgba(25, 196, 100, 0.47)',
          '.avatar': {
            bg: '#26BF69',
            '.icon': {
              color: '#fff'
            }
          },
          '.subtitle': {
            color: '#3D3F40'
          },
          '.title': {
            color: '#0C324F'
          },
          '.badge': {
            bg: '#2ABC6B',
            color: '#fff'
          }
        }
      },
      '.content_pengerjaan': {
        paddingBottom: '100px'
      },
      '.footer_menu_fixed': {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        bg: '#E9EDF0',
        boxShadow: '0px -1px 2px rgba(0, 0, 0, 0.12), 0px -4px 8px rgba(0, 0, 0, 0.1)',
        textAlign: 'end',
        py: '16px',
        mt: '24px'
      }
    }
  },
  components: {
    // insert custom style
  }
});
