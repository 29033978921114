import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authenticated } from 'store';

export default function Guest(props) {
  const history = useNavigate();
  const auth = useRecoilValue(authenticated);
  useEffect(() => {
    if (auth.check) {
      history('/home');
    }
  });
  return props.render;
}
